/* Custom Fonts */
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa2JL7SUc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa0ZL7SUc.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa2ZL7SUc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa1pL7SUc.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa2pL7SUc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa25L7SUc.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa1ZL7.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

/* Default CSS */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  /* scroll-padding-top: 75px; */
}

::-webkit-scrollbar {
  width: 5px;
  background: #d3d3d3;
}

::-webkit-scrollbar-track {
  background: #1f242d;
}

::-webkit-scrollbar-thumb {
  background: #323946;
}

:root {
  --bg-color: #121212;
  --second-bg-color: #003f61;
  --third-bg-color: #78222f;
  --linear-bg-default: linear-gradient(90.21deg, #78222f -100%, #003f61 111.58%);
  --light-gold-gradient: linear-gradient(90.21deg, #D38C50 -100%, #644D36 200.58%);
  --dark-gold-gradient: linear-gradient(90.21deg, #644D36 -100%, #2E2319 111.58%);
  --gold-bg-dark: #2E2319;
  --success-color: #008000;
  --danger-color: #ff0000;
  --text-color: #fff;
  --text-gold-dark: #D38C50;
  --text-label-dark: #525252;
  --fa-style-family-brands: "Font Awesome 6 Brands";

}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  color: var(--text-color);
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  font-weight: lighter;
  text-align: left;
  list-style-position: inside;
}

.demo-wrapper {
  background-color: #2c2c2c;
  color: #555555;
}

.toolbar-class {
  background-color: #3a3a3a;
  color: #555555;
  border: none;
}

.editor-class {
  background-color: #2c2c2c;
  color: #666666;
  border: 1px solid #4a4a4a;
  padding: 10px;
  min-height: 200px;
}

.editor-class .public-DraftEditor-content {
  color: #ffffff;
}

.editor-class a {
  color: var(--text-gold-dark);
}

.rdw-editor-toolbar {
  background-color: #3a3a3a;
  border: none;
}

.rdw-dropdown-selectedtext {
  color: #2c2c2c;
}

.rdw-dropdownoption-active {
  background: #090c15;
  color: #fff;
}

.rdw-dropdownoption-default {
  background-color: #8c8c8c;
  color: #090c15;
}

.rdw-link-modal {
  background-color: var(--bg-color);
}

.rdw-link-modal-label {
  background-color: var(--bg-color);
  color: var(--text-gold-dark)
}

.rdw-link-modal-btn {
  background-color: var(--bg-color);
  padding: 5px;
}

.rdw-option-wrapper {
  background-color: var(--text-label-dark);
  color: #ffffff;
  border: none;
}

.rdw-option-wrapper img {
  filter: invert(1);
}

/* Hide link preview */
/* .rdw-link-decorator-wrapper {
  display: none;
} */

.rdw-link-decorator-icon {
  display: none;
}


.rdw-dropdown-selectedtext {
  color: #2c2c2c;
  size-adjust: none;
}

.rdw-dropdownoption-active {
  background: #090c15;
  color: #fff;
}

.rdw-dropdownoption-active {
  color: red;
  background: #090c15;
  background-color: #090c15;
}

.rdw-dropdownoption-default {
  background-color: #8c8c8c;
  color: #090c15;
}

.learn-more {
  background-color: var(--gold-bg-dark);
  color: var(--text-gold-dark);
  border: none;
}

.learn-more:hover {
  background-color: var(--text-gold-dark);
  color: var(--gold-bg-dark);
  border: none;
}

.learn-more:active:hover {
  background-color: var(--gold-bg-dark);
  color: var(--text-gold-dark);
  border: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

input::placeholder,
textarea::placeholder {
  color: darkgray;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: var(--success-color);
}

p.danger {
  color: var(--danger-color);
}

a.success {
  color: var(--success-color);
}

a.danger {
  color: var(--danger-color);
}

.mb-60 {
  margin-bottom: 60px;
}

/************ Navbar Css ************/
/* Custom CSS for megamenu */
.nav-item {
  position: relative;
}

.nav-item .megamenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #343a40;
  padding: 1rem;
  z-index: 2;
  width: 200px;
}

.nav-item:hover .megamenu {
  display: block;
}

.nav-item .megamenu .navbar-link {
  display: block;
  padding: 1rem 1rem;
  color: #fff;
  text-decoration: none;
}

.nav-item .megamenu .navbar-link:hover {
  background-color: #495057;
}

.megamenu-divider {
  height: 1px;
  background-color: #495057;
  margin: 0.5rem 0;
}

/******* Smaller screen than 1200px *******/
@media (max-width:800px) {
  nav.navbar {
    background-color: var(--second-bg-color);
    padding: 20px 40px;

  }

  nav.navbar a.navbar-link {
    margin-top: 20px;
  }

  span.navbar-text {
    margin-top: 30px;
  }


}

/********** Normal screen **********/

nav.navbar {
  padding: 25px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
  transition: 0.32s ease-in-out;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: var(--second-bg-color);
  box-shadow: 0 1px 3px var(--third-bg-color) !important;
}

nav.navbar a.navbar-brand {
  color: var(--text-color);
  width: 9%;
}

nav.navbar a.navbar-link {

  color: var(--text-color);
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: #d9d9d91a;
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: var(--text-color);
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1.2);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}



nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  /* background-color: #003f61; */
  color: var(--text-color) !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
  /* margin-top: 30px; */
}



.navbar:link:hover {
  background-color: var(--text-color);
  text-decoration: none;
}

.navbar-text button {
  font-weight: 700;
  color: var(--text-color) !important;
  border: 1px solid var(--text-color);
  /* padding: 18px 34px; */
  font-size: 18px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  align-items: center;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: var(--text-color) !important;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #121212;
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

.navbar-text button img {
  width: 30px;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}


nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid var(--text-color);
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid var(--text-color);
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: var(--text-color);
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: var(--text-color);
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: var(--text-color);
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;

}


/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 130px 0;
  background-image: url('./assets/backgrounds/homebg.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner h1 {
  font-size: 65px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 0.8;
  /* margin-bottom: 5px; */
  display: block;
}

.banner h1 .bold {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 0.8;
  /* margin-bottom: 5px; */
  display: block;
}

.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

/* .banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg {
  margin-left: 25px;
} */

.banner a {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  /* margin-top: 60px; */
  letter-spacing: 0.8px;
  /* display: flex; */
  /* align-items: center; */
}

.banner a svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner a:hover svg {
  margin-left: 25px;
}

/************ banner-small Css ***********/
.banner-small {
  margin-top: 0;
  padding: 160px 0 130px 0;
  background-image: url('./assets/backgrounds/homebg.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-small .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner-small .headerImg img {
  height: 150px;
  width: auto;
}

/************ nextMeeting Css ************/
.nextMeeting {
  padding: 150px 0 150px 0;
  background: var(--linear-bg-default);
}

.nextMeeting h2 {
  font-size: 45px;
  font-weight: 500;
  text-align: center;
}

.nextMeeting p {
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.nextMeeting .card {
  width: 100%;
  min-height: 200px;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  background-color: #090c15 !important;
  color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
}

.nextMeeting .card:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
  transform: scale(1.01, 1.01)
}

.nextMeeting .card::after {
  opacity: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease-in-out;
}

.nextMeeting .card:hover::after {
  opacity: 1;
}

.nextMeeting a {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 20px;
  margin-left: 30px;
  margin-bottom: 30px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.nextMeeting a svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.nextMeeting a:hover svg {
  margin-left: 25px;
}

.nextMeeting-overlay h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

/************ ForYou Css ************/
.foryou {
  padding: 150px 0 150px 60px;
  background: var(--linear-bg-default);
  opacity: 1;
}

.foryou h2 {
  font-size: 42px;
  text-align: center;
  color: #ffffff;
}

.foryou p {
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  /* margin: 14px auto 30px auto; */
  text-align: center;
  width: 96%;
}

.foryou svg {
  /* padding-top: 90px; */
  margin: auto;
  margin-top: 30px;
  width: 50%;
  width: 160px;
  height: 160px;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.foryou .card {
  width: 80%;
  height: 500px;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  background-color: #090c15 !important;
  color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
}

.foryou .card:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
  transform: scale(1.01, 1.01)
}

.foryou .card::after {
  opacity: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease-in-out;
}

.foryou .card h4 {
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
  margin-top: 50px;
}

.foryou .card p {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.8px;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 50px;
  /* margin-bottom: 50px; */
}

/************ Info Css ************/

.info {
  padding: 150px 0;
  background: var(--bg-color);
  position: relative;
}

.info p {
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.info h2 {
  color: #D38C50;
  font-size: 42px;
  background: var(--light-gold-gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.info-cardbx {
  position: relative;
  background: var(--dark-gold-gradient);
  border-radius: 10px;
  border: 0;
  overflow: hidden;
  margin-bottom: 24px;
  color: #ffffff;
}

.info-cardbx::before {
  content: "";
  background: var(--dark-gold-gradient);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.info-cardbx:hover::before {
  height: 100%;
}

.info-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}

.info-cardbx:hover .info-txtx {
  top: 50%;
  opacity: 1;
}

.info-cardbx .btn {
  background-color: var(--gold-bg-dark);
  color: var(--text-gold-dark);
  border: none;
}


.info-overlay h4 {
  font-size: 30px;
  font-weight: 700;
  padding-top: 50px;
  letter-spacing: 0.8px;
  line-height: 1.1em;
  text-shadow: -1px 0 var(--bg-color), 0 1px var(--bg-color), 1px 0 var(--bg-color), 0 -1px var(--bg-color);
}

.info-overlay p {
  /* padding-top: 50px; */
  color: var(--bg-color);
  /* font-size: 25px; */
  text-shadow: -1px 0 var(--text-color), 0 1px var(--text-color), 1px 0 var(--text-color), 0 -1px var(--text-color);
}

.info-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.info-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

/************ Contact Css ************/
.contact {
  background: var(--linear-bg-default);
  padding: 60px 0 60px 0;
}

.contact img {
  width: 92%;
}

.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}

.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: var(--text-color);
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: var(--text-color);
}

.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: var(--bg-color);
  opacity: 0.8;
}

.contact form button {
  font-weight: 700;
  color: var(--bg-color);
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact form button span {
  z-index: 1;
  position: relative;
}

.contact form button:hover {
  color: var(--text-color);
}

.contact form button::before {
  content: "";
  background: var(--bg-color);
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.contact form button:hover::before {
  width: 100%;
}

/************ Meetings Css ************/
.meetings {
  background: var(--linear-bg-default);
  padding: 150px 0;
  min-height: 100vh;
  position: relative;
}

.meetings .title {
  margin-top: 0;
  /* margin-top: -80px; */
}

.meetings .actions {
  margin-top: 50px;
  /* margin-top: -40px; */
}

.meetings p {
  color: var(--text-color);
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1em;
  margin: 14px auto 14px auto;
  text-align: center;
  width: 56%;
}

.formInput::placeholder,
.formInput textarea::placeholder {
  color: var(--text-label-dark);
}

.meetings .header {
  display: flex;
  align-items: center;
}

.meetings-cardbx {
  position: relative;
  background: var(--dark-gold-gradient);
  border-radius: 10px;
  border: 1;
  overflow: hidden;
  margin-bottom: 10px;
  color: var(--text-color);
  /* height: 238px; */
}

.meetings-cardbx::before {
  content: "";
  background: var(--dark-gold-gradient);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.meetings-cardbx:hover::before {
  height: 100%;
}

.bigCard .not-found-title {
  margin-top: -75px;
  margin-left: 0;
  font-size: 24px;
  max-width: 300px;
  text-align: left;
  padding-left: 5px;
}

.not-found-title {
  margin-top: 47%;
  margin-left: 10px;
  position: absolute;
  text-transform: uppercase;
  font-size: 16px;
}

.meetings-card-dropdown {
  position: relative;
  margin-top: 0;
  margin-right: 0;
}

.meetings-txtx {
  position: absolute;
  text-align: center;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}

.meetings-cardbx:hover .meetings-txtx {
  top: 50%;
  opacity: 1;
}

.meetings-cardbx .btn {
  background-color: var(--gold-bg-dark);
  color: var(--text-gold-dark);
}


.meetings-overlay h4 {
  font-size: 30px;
  font-weight: 700;
  padding-top: 50px;
  letter-spacing: 0.8px;
  line-height: 1.1em;
  text-shadow: -1px 0 var(--bg-color), 0 1px var(--bg-color), 1px 0 var(--bg-color)lack, 0 -1px var(--bg-color);
}

.meetings-overlay p {
  /* padding-top: 50px; */
  color: black;
  /* font-size: 25px; */
  text-shadow: -1px 0 var(--text-color), 0 1px var(--text-color), 1px 0 var(--text-color), 0 -1px var(--text-color);
}

.meetings-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.meetings-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}


.meetings .cardDropdown {
  margin-top: -30px;
}

/************ Meeting Css ************/

.sticky-card {
  position: fixed;
  transition: top 0.3s ease-in-out;

}

.meeting {
  background: var(--linear-bg-default);
  padding: 150px 0;
  position: relative;
  min-height: 100vh;
}

.meeting .summary {
  padding: 50px 0;
}

.meeting .summary-item {
  padding: 8px 5px;
}

.meeting .summary h4 {
  font-size: 18px;
  font-weight: normal;
}

.meeting .summary h5 {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-label-dark);
}

.meeting .summary span {
  font-size: 14px;
  font-weight: normal;
}

.meeting .timeButton {
  height: 22px;
  padding: 2px;
  margin-right: 5px;
}

.assignments-button {
  margin-top: 5px;
  height: 30px;
  padding-top: 2px;
  /* padding-right: 5px; */
  padding-bottom: 6px;
}

.addAgendaItem h2 {
  overflow: hidden;
  text-align: center;
  color: var(--text-label-dark);
  font-size: 14px;
}

.addAgendaItem h2::before,
.addAgendaItem h2::after {
  background-color: var(--text-label-dark);
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.addAgendaItem h2::before {
  right: 0.5rem;
  margin-left: -50%;
}

.addAgendaItem h2::after {
  left: 0.5rem;
  margin-right: -50%;
}

.addAgendaItem h2:hover {
  color: var(--text-color);
  cursor: pointer;
}

.large .timeButton {
  height: 60px;
  padding: 6px;
  margin-right: 5px;
  font-size: 32px;
}

.large h1 {
  font-size: 92px;
  font-weight: bold;
}

.large h2 {
  font-size: 72px;
  font-weight: normal;
}

.large h3 {
  font-size: 62px;
  font-weight: normal;
}

.timer {
  margin: 0;
  /* border: solid 1px red; */
}

.timer a {
  color: var(--text-gold-dark);
}

.clock {
  /* border: solid 1px white; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15vh;
  height: 16vh;
}

.timer-colors {
  background-color: var(--bg-color);
  margin: auto;
  height: calc(100% - 110px);
  width: 100%;
}

.golden-text {
  color: var(--text-gold-dark)
}

.timer h1 {
  font-size: 74px;
}

.timer h2 {
  font-size: 68px;
}

.timer h3 {
  font-size: 52px;
}

.timer h4 {
  font-size: 46px;
}

.timer h5 {
  font-size: 36px;
}

.control-button {
  width: 48px;
  height: 48px;
}

.text-inline {
  display: inline;
}

.limits {
  font-size: 36px;
}


@media (max-width: 400px) {
  .limits {
    font-size: 16px;
  }

  .hexagon {
    max-height: 150px;
    max-width: 150px;
  }

  .text-inline {
    display: inline;
  }

  .timer-header h1 {
    font-size: 22px;
  }

  .timer-header h2 {
    font-size: 18px;
  }

  .timer-header h3 {
    font-size: 16px;
  }

  .timer-header h4 {
    font-size: 14px;
  }

  .timer-header h5 {
    font-size: 12px;
  }

  /* .timer-colors {
    height: 95vw;
  } */

  .clock {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15vw;
    /* 80% of the width */
    /* height: 100%; */
    /* Center vertically */
  }

  .control-button {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 600px) {
  .timer h1 {
    font-size: 48px;
  }

  .timer h2 {
    font-size: 42px;
  }

  .timer h3 {
    font-size: 36px;
  }

  .timer h4 {
    font-size: 28px;
  }

  .timer h5 {
    font-size: 22px;
  }

  /* .timer-colors {
    height: 45vh;
  } */

  .clock {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15vw;
    /* 80% of the width */
    /* height: 100%; */
    /* Center vertically */
  }
}

@media (min-width: 601px) and (max-width: 1080px) {
  .timer h1 {
    font-size: 48px;
  }

  .timer h2 {
    font-size: 42px;
  }

  .timer h3 {
    font-size: 36px;
  }

  .timer h4 {
    font-size: 28px;
  }

  .timer h5 {
    font-size: 22px;
  }

  /* .timer-colors {
    height: 55vh;
  } */


  .clock {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15dvh;
    /* margin-top: -100px; */
    /* 80% of the width */
    /* height: 100%; */
    /* Center vertically */
  }
}

@media (min-width: 1081px) {
  .timer h1 {
    font-size: 48px;
  }

  .timer h2 {
    font-size: 42px;
  }

  .timer h3 {
    font-size: 36px;
  }

  .timer h4 {
    font-size: 28px;
  }

  .timer h5 {
    font-size: 22px;
  }

  /* .timer-colors {
    height: 20vh;
  } */
}

/************ Roles Css ************/

.roles {
  background: var(--linear-bg-default);
  padding: 100px 0;
  min-height: 100vh;
  /* position: relative; */
}

.roles .title {
  margin-top: 0;
  /* margin-top: -80px; */
}

.roles .actions {
  margin-top: 0;
  /* margin-top: -40px; */
}

.roles p {
  color: var(--text-color);

}

.roles-card {
  text-align: justify;

}

.roles-card a {
  text-align: justify;
  color: var(--text-gold-dark)
}

/* Custom CSS for EventsComponent */

.events-container {
  /* background: var(--linear-bg-default); */
  padding-top: 120px;
  position: relative;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x)* .5);
  padding-left: calc(var(--bs-gutter-x)* .5);
  margin-right: auto;
  margin-left: auto;
  /* Adjust based on the height of the fixed rows */
}

.events-fixed-top {
  position: fixed;
  width: 100%;
  z-index: 1;
  background: var(--second-bg-color);
  padding-left: 15px;
  padding-right: 15px;
  /* Adjust based on your theme */
}

.fixed-wrapper {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x)* .5);
  padding-left: calc(var(--bs-gutter-x)* .5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1400px) {
  .fixed-wrapper {
    max-width: 1320px;
  }
}

@media (min-width: 1200px) {
  .fixed-wrapper {
    max-width: 1140px;
  }
}

@media(min-width: 992px) {
  .fixed-wrapper {
    max-width: 960px;
  }
}

@media(min-width: 768px) {
  .fixed-wrapper {
    max-width: 720px;
  }
}

@media(min-width: 576px) {
  .fixed-wrapper {
    max-width: 540px;
  }
}

.search-row {
  top: 200px;
  /* Adjust based on the height of the header row */
  left: 0;
  right: 0;
  padding: 15px;
  background: var(--second-bg-color);
}

.events-list {
  margin-top: 20px;
  /* Adjust based on the height of the fixed rows */
  overflow-y: auto;
  max-height: calc(100vh - 120px);
  /* Adjust based on the height of the fixed rows */
}

/************ Profile Css ************/

.profile {
  background: var(--linear-bg-default);
  padding: 120px 0;
  position: relative;
  min-height: 100vh;
  display: flex;
}

/************ Footer Css ************/
.footer {
  padding: 0 0 0 0;
  /* margin-top: -80px; */
  padding-top: 120px;
  background: var(--bg-color);
  height: 340px;
  /* z-index: -1; */

}

.newsletter-bx {
  margin: auto;
  background: var(--bg-color);
  border-radius: 55px;
  color: var(--text-color);
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -300px;
  width: 80%;
  /* z-index: 0; */
}

.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}

.new-email-bx {
  background: var(--text-color);
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}

.new-email-bx::before {
  content: "";
  background: var(--linear-bg-default);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}

.new-email-bx::after {
  content: "";
  background: var(--text-color);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.new-email-bx input {
  width: 100%;
  color: var(--bg-color);
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}

.new-email-bx button {
  background: var(--linear-bg-default);
  padding: 20px 65px;
  color: var(--text-color);
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}

/* .footer img {
  width: 26%;
} */
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: var(--text-label-dark);
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.tc {
  padding: 80px 0 150px 60px;
  background: var(--linear-bg-default);
  opacity: 1;
}

.tc h1 {
  font-size: 32px;
  text-align: left;
  color: var(--text-color);
  font-weight: 400;
  padding: 10px 0;
}

.tc p {
  color: var(--text-color);
  font-size: 14px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  font-weight: lighter;
  text-align: left;
  padding: 5px 0;
}

.tc li {
  color: var(--text-color);
  font-size: 14px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  font-weight: lighter;
  text-align: left;
  list-style-position: inside;
}