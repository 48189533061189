:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

:root {
  --bs-text-muted: #636674;
  --bs-gray-100: #1B1C22;
  --bs-gray-100-rgb: 27, 28, 34;
  --bs-gray-200: #26272F;
  --bs-gray-200-rgb: 38, 39, 47;
  --bs-gray-300: #363843;
  --bs-gray-300-rgb: 54, 56, 67;
  --bs-gray-400: #464852;
  --bs-gray-400-rgb: 70, 72, 82;
  --bs-gray-500: #636674;
  --bs-gray-500-rgb: 99, 102, 116;
  --bs-gray-600: #808290;
  --bs-gray-600-rgb: 128, 130, 144;
  --bs-gray-700: #9A9CAE;
  --bs-gray-700-rgb: 154, 156, 174;
  --bs-gray-800: #B5B7C8;
  --bs-gray-800-rgb: 181, 183, 200;
  --bs-gray-900: #F5F5F5;
  --bs-gray-900-rgb: 245, 245, 245;
  --bs-light: #1B1C22;
  --bs-primary: #006AE6;
  --bs-secondary: #363843;
  --bs-success: #00A261;
  --bs-info: #883FFF;
  --bs-warning: #C59A00;
  --bs-danger: #E42855;
  --bs-dark: #272A34;
  --bs-primary-active: #107EFF;
  --bs-secondary-active: #464852;
  --bs-light-active: #1F212A;
  --bs-success-active: #01BF73;
  --bs-info-active: #9E63FF;
  --bs-warning-active: #D9AA00;
  --bs-danger-active: #FF3767;
  --bs-dark-active: #2D2F39;
  --bs-primary-light: #172331;
  --bs-secondary-light: #363843;
  --bs-success-light: #1F212A;
  --bs-info-light: #272134;
  --bs-warning-light: #242320;
  --bs-danger-light: #302024;
  --bs-dark-light: #1E2027;
  --bs-light-light: #1F212A;
  --bs-primary-inverse: #ffffff;
  --bs-secondary-inverse: #ffffff;
  --bs-light-inverse: #808290;
  --bs-success-inverse: #ffffff;
  --bs-info-inverse: #ffffff;
  --bs-warning-inverse: #ffffff;
  --bs-danger-inverse: #ffffff;
  --bs-dark-inverse: #ffffff;
  --bs-primary-clarity: rgba(0, 106, 230, 0.2);
  --bs-secondary-clarity: rgba(54, 56, 67, 0.2);
  --bs-success-clarity: rgba(0, 162, 97, 0.2);
  --bs-info-clarity: rgba(136, 63, 255, 0.2);
  --bs-warning-clarity: rgba(197, 154, 0, 0.2);
  --bs-danger-clarity: rgba(228, 40, 85, 0.2);
  --bs-dark-clarity: rgba(39, 42, 52, 0.2);
  --bs-light-clarity: rgba(31, 33, 42, 0.2);
  --bs-light-rgb: 27, 28, 34;
  --bs-primary-rgb: 0, 106, 230;
  --bs-secondary-rgb: 54, 56, 67;
  --bs-success-rgb: 0, 162, 97;
  --bs-info-rgb: 136, 63, 255;
  --bs-warning-rgb: 197, 154, 0;
  --bs-danger-rgb: 228, 40, 85;
  --bs-dark-rgb: 39, 42, 52;
  --bs-text-white: #ffffff;
  --bs-text-primary: #006AE6;
  --bs-text-secondary: #363843;
  --bs-text-light: #1B1C22;
  --bs-text-success: #00A261;
  --bs-text-info: #883FFF;
  --bs-text-warning: #C59A00;
  --bs-text-danger: #E42855;
  --bs-text-dark: #272A34;
  --bs-text-muted: #636674;
  --bs-text-gray-100: #1B1C22;
  --bs-text-gray-200: #26272F;
  --bs-text-gray-300: #363843;
  --bs-text-gray-400: #464852;
  --bs-text-gray-500: #636674;
  --bs-text-gray-600: #808290;
  --bs-text-gray-700: #9A9CAE;
  --bs-text-gray-800: #B5B7C8;
  --bs-text-gray-900: #F5F5F5;
  --bs-border-color: #26272F;
  --bs-border-dashed-color: #363843;
  --bs-component-active-color: #ffffff;
  --bs-component-active-bg: #006AE6;
  --bs-component-hover-color: #006AE6;
  --bs-component-hover-bg: #1B1C22;
  --bs-component-checked-color: #ffffff;
  --bs-component-checked-bg: #006AE6;
  --bs-box-shadow-xs: 0 0.1rem 0.75rem 0.25rem rgba(0, 0, 0, 0.05);
  --bs-box-shadow-sm: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05);
  --bs-box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 2rem 1rem rgba(0, 0, 0, 0.1);
  --bs-input-color: var(--bs-gray-700);
  --bs-input-bg: var(--bs-body-bg);
  --bs-input-solid-color: var(--bs-gray-700);
  --bs-input-solid-bg: var(--bs-gray-100);
  --bs-input-solid-bg-focus: var(--bs-gray-200);
  --bs-input-solid-placeholder-color: var(--bs-gray-500);
  --bs-tooltip-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  --bs-root-card-box-shadow: none;
  --bs-root-card-border-color: #1E2027;
  --bs-table-striped-bg: rgba(27, 28, 34, 0.75);
  --bs-table-loading-message-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
  --bs-dropdown-bg: #1C1D22;
  --bs-dropdown-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
  --bs-code-bg: #2b2b40;
  --bs-code-shadow: rgba(0, 0, 0, 0.08) 0px 3px 9px 0px;
  --bs-code-border-color: transparent;
  --bs-code-color: #b93993;
  --bs-symbol-label-color: #B5B7C8;
  --bs-symbol-label-bg: #1B1C22;
  --bs-symbol-border-color: rgba(255, 255, 255, 0.5);
  --bs-bullet-bg-color: #464852;
  --bs-scrolltop-opacity: 0;
  --bs-scrolltop-opacity-on: 0.3;
  --bs-scrolltop-opacity-hover: 1;
  --bs-scrolltop-box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
  --bs-scrolltop-bg-color: #1B84FF;
  --bs-scrolltop-bg-color-hover: #1B84FF;
  --bs-scrolltop-icon-color: #ffffff;
  --bs-scrolltop-icon-color-hover: #ffffff;
  --bs-drawer-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  --bs-drawer-bg-color: #1C1D22;
  --bs-drawer-overlay-bg-color: rgba(0, 0, 0, 0.4);
  --bs-menu-dropdown-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
  --bs-menu-dropdown-bg-color: #1C1D22;
  --bs-menu-heading-color: #636674;
  --bs-menu-link-color-hover: #006AE6;
  --bs-menu-link-color-show: #006AE6;
  --bs-menu-link-color-here: #006AE6;
  --bs-menu-link-color-active: #006AE6;
  --bs-menu-link-bg-color-hover: #1B1C22;
  --bs-menu-link-bg-color-show: #1B1C22;
  --bs-menu-link-bg-color-here: #1B1C22;
  --bs-menu-link-bg-color-active: #1B1C22;
  --bs-scrollbar-color: #26272F;
  --bs-scrollbar-hover-color: #363843;
  --bs-overlay-bg: rgba(255, 255, 255, 0.05);
  --bs-blockui-overlay-bg: rgba(255, 255, 255, 0.05);
  --bs-rating-color-default: #464852;
  --bs-rating-color-active: #FFAD0F;
  --bs-ribbon-label-box-shadow: 0px -1px 5px 0px rgba(255, 255, 255, 0.1);
  --bs-ribbon-label-bg: #1B84FF;
  --bs-ribbon-label-border-color: #0053b4;
  --bs-ribbon-clip-bg: #F9F9F9;
  --bs-engage-btn-bg: #26272F;
  --bs-engage-btn-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
  --bs-engage-btn-border-color: #26272F;
  --bs-engage-btn-color: #B5B7C8;
  --bs-engage-btn-icon-color: #808290;
  --bs-engage-btn-color-active: #B5B7C8;
  --fa-style-family-brands: "Font Awesome 6 Brands";
  --fa-font-brands: normal 400 1em / 1 "Font Awesome 6 Brands";
}

:root {
  font-family: var(--fa-style-family-brands);

}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: var(--bs-gray-900);
  --bs-card-border-width: 1px;
  /* --bs-card-border-color: #F1F1F4; */
  --bs-card-border-radius: 0.625rem;
  --bs-card-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  --bs-card-inner-border-radius: calc(0.625rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: transparent;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-title-color);
  word-wrap: break-word;
  background-color: var(--bs-dark);
  background-clip: border-box;
  /* border: var(--bs-card-border-width) solid var(--bs-card-border-color); */
  border-radius: var(--bs-card-border-radius);
  box-shadow: var(--bs-card-box-shadow);
}

.avatar {
  margin: auto;
  filter: drop-shadow(5px .9vw 1vw #78222f8d);
  width: 18rem;
}

.avatar .hexagon {
  position: relative;
  /* width: 25rem; */
  height: 18rem;
  clip-path: polygon(47.5% 5.66987%,
      48.2899% 5.30154%,
      49.13176% 5.07596%,
      50% 5%,
      50.86824% 5.07596%,
      51.7101% 5.30154%,
      52.5% 5.66987%,
      87.14102% 25.66987%,
      87.85495% 26.16978%,
      88.47124% 26.78606%,
      88.97114% 27.5%,
      89.33948% 28.2899%,
      89.56505% 29.13176%,
      89.64102% 30%,
      89.64102% 70%,
      89.56505% 70.86824%,
      89.33948% 71.7101%,
      88.97114% 72.5%,
      88.47124% 73.21394%,
      87.85495% 73.83022%,
      87.14102% 74.33013%,
      52.5% 94.33013%,
      51.7101% 94.69846%,
      50.86824% 94.92404%,
      50% 95%,
      49.13176% 94.92404%,
      48.2899% 94.69846%,
      47.5% 94.33013%,
      12.85898% 74.33013%,
      12.14505% 73.83022%,
      11.52876% 73.21394%,
      11.02886% 72.5%,
      10.66052% 71.7101%,
      10.43495% 70.86824%,
      10.35898% 70%,
      10.35898% 30%,
      10.43495% 29.13176%,
      10.66052% 28.2899%,
      11.02886% 27.5%,
      11.52876% 26.78606%,
      12.14505% 26.16978%,
      12.85898% 25.66987%);
  background: var(--second-bg-color);
  overflow: hidden;
}

.avatar .hexagon img {
  padding-top: 15%;
  padding-left: 5%;
  width: 95%;
  height: auto;
  display: block;
  filter: drop-shadow(10px 5px 7px rgba(0, 0, 0, .5));
}

.name {
  font-size: 18px;
  text-transform: uppercase;
  margin: auto;
  margin-top: -30px;
}

.smaller {
  font-size: 10px;
  font-weight: 100;
}