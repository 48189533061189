* {
  box-sizing: border-box;
}

body,
html {
  /* background-image: url('/public/img/backgrounds/bg6-dark.jpg'); */
  display: flex;
  background-attachment: fixed;
  background-repeat: no-repeat;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: 100%;
  /* text-size-adjust: 100%; */
  font-size: 13px !important;
  font-weight: 300;
  /* font-family: Helvetica,sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a {
  color: #009EF7;
  text-decoration: none;
}

div {
  display: block;
}

h1 {
  font-size: 1.75rem;
}

input {
  color: #92929F;

}

::placeholder {
  color: #92929F;
}

.align-middle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* border: 3px solid green;  */
}

.app-blank {
  background-color: #151521;
}

.bgi-no-repeat {
  background-repeat: no-repeat;
}

.bgi-position-center {
  background-position: center;
}

.bgi-size-cover {
  background-size: cover;
}

.btn {
  --bs-btn-color: #FFFFFF;
  --bs-btn-border-color: transparent;
  outline: 0 !important;
}

.btn.btn-flex {
  display: inline-flex;
  align-items: center;
}

.btn.btn-outline {
  border: 1px solid #323248;
}

.btn.btn-text-gray-700 {
  color: #92929F;
}

.d-flex {
  display: flex !important;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-fluid {
  flex: 1 0 auto;
}

.flex-lg-row {
  flex-direction: row !important;
}

.flex-lg-start {
  justify-content: start;
  align-items: start;
}

.flex-root {
  flex: 1;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.775rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #92929F;
  background-color: #1e1e2d;
  background-clip: padding-box;
  border: 1px solid #323248;
  appearance: none;
  border-radius: 0.475rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #92929F;
  opacity: 0.5;
  /* Firefox */
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #92929F;
}

.form-control:focus {
  color: #92929F;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.flex-stack {
  justify-content: space-between;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.fs-base {
  font-size: 1rem !important;
}

.fs-6 {
  font-size: 1.075rem !important;
}

.fs-7 {
  font-size: .95rem !important;
}

.fw-bolder {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 500 !important;
}

.h-15px {
  height: 15px !important;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 0.75rem;
}

.gap-3 {
  gap: 0.75rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.mb-11 {
  margin-bottom: 2.75rem !important;
}

.me-3 {
  margin-right: 0.75rem !important;
}

.my-14 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.pt-lg-0 {
  padding-top: 0 !important;
}

.p-lg-20 {
  padding: 5rem !important;
}

.pt-15 {
  padding-top: 3.75rem !important;
}

.px-10 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}


.rounded-3 {
  border-radius: 0.625rem !important;
}

.semi-transparent {
  opacity: .9;
}

.separator {
  display: block;
  height: 0;
  border-bottom: 1px solid #2B2B40;
}

.separator-content {
  /* display: flex; */
  /* align-items: center; */
  border-bottom: 0;
  text-align: center;
}

.signinCard {
  width: 350px;
}

.text-black {
  color: #000000;
}

.text-center {
  text-align: center !important;
}

.text-dark {
  color: #FFFFFF !important;
}

.text-gray-500 {
  color: #565674 !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.w-100 {
  width: 100% !important;
}

.w-s-50 {
  width: 50% !important;
}

.w-125px {
  width: 125px !important;
}

.w-s-300px {
  width: 300px !important;
}

.w-lg-50 {
  width: 50% !important;
}

.w-lg-80 {
  width: 80% !important;
}


@media (min-width: 912px) {
  .signinCard {
    width: 450px;
  }
}